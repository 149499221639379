<template>
  <v-container
    fluid
  >

    <v-row>
      <v-col>
        <v-btn
          @click="dialogNewCourse = true"
        >
          New Course
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="courses"
          :items-per-page="10"
        >
        <!--<template v-slot:item.context="{ item }">

          <v-menu
            offset-y
          >

            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>

            <v-list>

              <v-list-item 
                @click="showActivateCourseDialog(item)" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Activate Course</v-list-item-title>
              </v-list-item>

              <v-list-item 
                @click="showChangePasswordDialog(item);" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>

            </v-list>

          </v-menu>

        </template>   -->    
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialog: New Course -->

    <v-dialog
      v-model="dialogNewCourse"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          New Course
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="title"
              label="Title"
            ></v-text-field>         

            <v-select
              v-model="publisherId"
              :items="publishers"
              label="Publisher"
              item-value="publisherid"
              item-text="name"
            ></v-select>

            <v-select
              v-model="localeId"
              :items="locales"
              label="Locale"
              item-value="localeid"
              item-text="description"
            ></v-select>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogNewCourse = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="newCourse"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');
  const LocaleRepository = RepositoryFactory.get('locale');
  const PublisherRepository = RepositoryFactory.get('publisher');

  export default {
    name: 'Courses',

    //components: {
    //  courseExplorer: () => import("@/components/CourseExplorer"),
    //  lessonViewer: () => import("@/components/LessonViewer"),
    //},

    data: () => ({
      headers: [      
        {
          text: 'Publisher',
          value: 'publisher.name',
        },
        {
          text: 'Course',
          value: 'title',
        },  
        {
          text: 'Locale',
          value: 'locale.description',
        },        
        {
          text: 'Status',
          value: 'status.description',
        },
        {
          value: 'context',
        },              
      ],      
      courses: [],
      locales: [],
      publishers: [],      
      dialogNewCourse: false,
      title: '',   
      publisherId: 0,   
      localeId: 0,
    }),

    mounted() {      
      this.loadPublishers();
      this.loadLocales();
      this.loadCourses();
    },

    methods: {

      async loadCourses() {
        this.courses = [];
        const { data } = await CourseRepository.listCourses();
        if (data.success) {          
          this.courses = data.data.courses;          
        } else {
          errorUtils.showError(data.message);
        }
      },

      async loadLocales() {
        this.locales = [];
        const { data } = await LocaleRepository.listLocales();
        if (data.success) {          
          this.locales = data.data.locales;
        } else {
          errorUtils.showError(data.message);
        }
      },

      async loadPublishers() {
        this.publishers = [];
        const { data } = await PublisherRepository.listPublishers();
        if (data.success) {          
          this.publishers = data.data.publishers;
        } else {
          errorUtils.showError(data.message);
        }
      },

      async newCourse() {

        const { data } = await CourseRepository.newCourse(
          this.title, 
          this.publisherId, 
          this.localeId
        );
        if (data.success) {                 
          this.title = ''; 
          this.publisherId = 0;  
          this.localeId = 0; 

          this.dialogNewCourse = false;
          this.loadCourses();

        } else {
          errorUtils.showError(data.message);
        }

      },

    },

  }
</script>
